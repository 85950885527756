import { Tag } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { OrderPaymentMethod } from '../../../models/order'

interface IProps {
  method: OrderPaymentMethod
}

const OrderPaymentMethodTag: React.FC<IProps> = ({ method }) => {
  const { t } = useTranslation()

  let label = ''

  switch (method) {
    case OrderPaymentMethod.MANUAL:
      label = t('order.payment_method_manual')
      break
    case OrderPaymentMethod.COIN_PAYMENTS:
      label = t('order.payment_method_coin_payments')
      break
    case OrderPaymentMethod.PERFECT_MONEY:
      label = t('order.payment_method_perfect_money')
      break
    case OrderPaymentMethod.PAYEER:
      label = t('order.payment_method_payeer')
      break
    case OrderPaymentMethod.LUNU:
      label = t('order.payment_method_lunu')
      break
  }

  return <Tag>{label}</Tag>
}

export default OrderPaymentMethodTag
