import { Descriptions, Modal } from 'antd'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { IExchangeOrderCard } from '../../../../models/exchange-order'

interface IProps {
  card?: IExchangeOrderCard
  visible: boolean
  setVisible: (visible: boolean) => void
}

const ExchangeOrderCardDetailsModal: React.FC<IProps> = ({
  card,
  visible,
  setVisible,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      title={t('exchange_order.card_details')}
      open={visible}
      onOk={() => setVisible(false)}
      okType="primary"
      onCancel={() => setVisible(false)}
      width={800}
    >
      <Descriptions bordered column={1}>
        <Descriptions.Item label={t('exchange_order.store')}>
          {card?.store}
        </Descriptions.Item>
        <Descriptions.Item label={t('exchange_order.category')}>
          {card?.category}
        </Descriptions.Item>
        <Descriptions.Item label={t('exchange_order.product')}>
          {card?.product}
        </Descriptions.Item>
        <Descriptions.Item label={t('exchange_order.type')}>
          {card?.type}
        </Descriptions.Item>
        <Descriptions.Item label={t('exchange_order.number')}>
          {card?.number}
        </Descriptions.Item>
        {card?.image && (
          <Descriptions.Item label={t('exchange_order.image')}>
            <img
              src={card?.image}
              alt="Card"
              style={{ width: 600, maxHeight: '60vh', objectFit: 'contain' }}
            />
          </Descriptions.Item>
        )}
      </Descriptions>
    </Modal>
  )
}

export default ExchangeOrderCardDetailsModal
