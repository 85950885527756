export enum ExchangeOrderPaymentMethod {
  CRYPTO = 'CRYPTO',
  PAYPAL = 'PAYPAL',
  PAYEER = 'PAYEER',
  PERFECT_MONEY = 'PERFECT_MONEY',
}

export enum ExchangeOrderStatus {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
  PAID = 'PAID',
}

export interface IExchangeOrder {
  id: string
  sequence: string
  fullName: string
  email: string
  phone: string
  status: ExchangeOrderStatus
  totalPrice: number
  currency: string
  paymentMethod: ExchangeOrderPaymentMethod
  createdAt: Date
  updatedAt: Date
  acceptedBy?: string
  rejectedBy?: string
  paidBy?: string
}

export interface IExchangeOrderCard {
  store: string
  category: string
  product: string
  type: string
  number: string
  image?: string
  price: number
}

export interface IExchangeOrderDetails extends IExchangeOrder {
  cryptoCurrency?: string
  cryptoNetwork?: string
  walletAddress?: string
  paypalEmail?: string
  note?: string
  rejectionReason?: string
  cards: IExchangeOrderCard[]
}

export interface IEditExchangeOrder {
  note?: string
  rejectionReason?: string
  totalPrice: number
}

export interface IRejectExchangeOrder {
  rejectionReason?: string
}
